import React, {useState, useEffect} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import sysApi from '../helpers/config';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';



const CreditCard = (props) => {
    const [openConfirm, setOpenConfirm] = useState(false);
    const [showFailAlert, setShowFailAlert] = useState(false);
    const [message, setMessage] = useState("");
    
    const handleRemoveCard = () => {
      setOpenConfirm(false)
      fetch(`${sysApi.host}/payment/${props.card_id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}`
            }
        }).then(function (response) {
            if (response.status == 200) {
                setShowFailAlert(false)
                response.json().then(function (object) {
                  console.log("removing payment success: ", object)
                  props.updatePage()
                })
            }else{
                setShowFailAlert(true)
                setMessage("Internal error, please contact support.")
                console.log("failed: ")
                console.log(response.json())
            }
        })
    }
    return (
        <Card variant="outlined">
          <CardContent>
                {
                  showFailAlert && <div>
                    <Alert severity="error">{message}</Alert>
                  </div>
                }
                <Typography>
                
                <img src={`${process.env.PUBLIC_URL}/assets/img/cards/${props.card_brand}.png`} alt={`${process.env.PUBLIC_URL}/assets/img/cards/card.png`}  width="50px" align="bottom" style={{ marginRight: "20px", verticalAlign:'middle' }} />
                <span>
                {props.card_brand.charAt(0).toUpperCase() + props.card_brand.slice(1)}: ***{props.card_digits}
                </span>
                </Typography>
                
            </CardContent>
            <CardActions>
                <Button
                 variant="contained" 
                  color="secondary"// "primary"
                  onClick={()=>setOpenConfirm(true)}
                >
                  Remove Card
                </Button>
                
                <Dialog
                      open={openConfirm}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                    <DialogTitle id="alert-dialog-title">Remove the card?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Are you sure you want to remove this card?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleRemoveCard} color="primary">
                        Yes
                      </Button>
                      <Button onClick={() => setOpenConfirm(false)} color="primary" autoFocus>
                        Cancel
                      </Button>
                    </DialogActions>
                </Dialog>
            </CardActions>
          
        </Card>
  );
}

export default CreditCard;