import React, { useState, useEffect, useRef  } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import sysApi from './helpers/config';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Grid from '@mui/material/Grid';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import {history} from './helpers/history';
import { useNavigate } from 'react-router-dom';

import CreditCard from './components/CreditCard';
import CheckoutFormApp from './components/CheckoutFormApp';
import PricingContent from './components/PricingContent';


function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary">
      {'Copyright © '}
      <Link color="inherit" href="https://testivr.com/">
        TestIVR
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function MainPage() {
  const [plan, setPlan] = useState(null);
  const [payment, setPayment] = useState(null);
  const [apiKey, setApiKey] = useState(null);
  const navigate = useNavigate();
  const handleUpdate = () => {
    
    fetch(`${sysApi.host}/payment/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}`
            }
        }).then(function (response) {
            if (response.status == 200) {
                response.json().then(function (object) {
                  if(object.data.length>0){
                    setPayment(object.data[0])
                  }else{
                    setPayment(null)
                  }
                
                })
            }else{
              setPayment(null)
              console.log(response.json())
            }
        })
        
    fetch(`${sysApi.host}/subscription/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}`
            }
        }).then(function (response) {
            if (response.status == 200) {
                response.json().then(function (object) {
                  console.log("subscription: ", object)
                  if(object.data.length>0){
                    setPlan(object.data[0])
                  }
                
                })
            }else{
              console.log(response.json())
            }
        })
    fetch(`${sysApi.host}/get_key/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}`
            }
        }).then(function (response) {
            if (response.status == 200) {
                response.json().then(function (object) {
                  console.log("subscription: ", object)
                  if(object.data.length>0){
                    setApiKey(object.data[0].key)
                  }
                
                })
            }else{
              console.log(response.json())
            }
        })
  }
  
  useEffect(() => {
        handleUpdate()
    }, []);

  
  return (
    
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <CssBaseline />
      <Grid
        container
        direction="column"
        justifyContent="space-around"
        alignItems="center"
      >
      <Box margin={2}>
      {
         plan!=null?
         (
         <PricingContent
         plan_type={plan.plan_description.plan_type}
         cancel_at_period_end={plan.cancel_at_period_end}
         current_period_end={plan.current_period_end}
         subscription_id={plan.id}
         updatePage={handleUpdate}
         payment={payment}
         >
         </PricingContent>
         )
         :
         <></>
       }
       </Box>
       <Box  sx={{
        width: 400,
        backgroundColor: '#3f51b5',
        margin: 2 
      }}>
       {
         payment==null?
         (
         <CheckoutFormApp
         updatePage={handleUpdate}
         >
         </CheckoutFormApp>
         )
         :
         <Box margin={2}>
         <CreditCard
         card_brand={payment.brand}
         card_digits={payment.last4}
         card_id={payment.id}
         updatePage={handleUpdate}
         >
         </CreditCard>
         </Box>
       }
       <Box margin={2}>
        <Card>
          <CardContent>
                <Typography style={{fontSize:18}} gutterBottom> API key:</Typography>
                  <TextField
                    disabled
                    value={apiKey}
                    fullWidth
                    inputProps={{style: {fontSize: 13}}}
                  />
                  <IconButton onClick={() => {
                  if ('clipboard' in navigator) {
                    return navigator.clipboard.writeText(apiKey)
                  } else {
                    return document.execCommand('copy', true, apiKey);
                  }
                  }}>
                    <ContentCopyIcon />
                  </IconButton>
                  
                
          </CardContent>
        </Card>
        </Box>
      </Box>
      <Button variant="outlined" onClick={() => {
        window.open("https://testivr.com/apidoc.html", "_blank")
      }}>
        API Document
      </Button>
      <Button variant="outlined" onClick={() => {
          localStorage.removeItem('token');
          navigate('/login');
        }}>Logout</Button>
      </Grid>
      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: 'auto',
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
        }}
      >
        <Container maxWidth="sm">
          <Typography variant="body1">
            TestIVR: Test your IVR programmatically.
          </Typography>
          <Copyright />
        </Container>
      </Box>
    </Box>
  );
}