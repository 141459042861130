var sysApi = {
        host: 'https://a5e0-34-234-54-130.ngrok.io',
        basic_plan_id: 'price_1Ke4vRGMtGWqPwD7fBMY8EJp',
        stripe_key: 'pk_test_51Kdxd9GMtGWqPwD7PJrDp2s5dsdyvwy4HmQZzwGwhWvQIdjzq9IaSJqOOkyuEjgQtD23tOCaqcx1njTadCZWIHaY007ClhaEo8',
    }
if (process.env.REACT_APP_TESTIVR_ENVIRONMENT === "PRODUCTION"){
    sysApi = {
        host: 'https://api.testivr.com',
        basic_plan_id: 'price_1L0C7FGMtGWqPwD7Hel0j67o',
        stripe_key: 'pk_live_51Kdxd9GMtGWqPwD75swzQ9pVLYftnm40Oao0qOOfsnJUvh3k3YxJ0jziIP5gV9N1zWCpvVNGBbY2R08sFhlqGpBd00ccbbSNk7',
    }
}    



export default sysApi;