import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import sysApi from '../helpers/config';
import Alert from '@material-ui/lab/Alert';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const tiers = [
  { 
    planType: 'free_plan',
    title: 'Free',
    price: '0',
    description: [
      'Maximum 2 Free Tests',
      'Maximum 3 Actions Per Test',
      'Maximum 10 Run Per Month',
      'Email support',
    ],
    buttonText: 'Select',
  },
  {
    planType: 'basic_plan',
    title: 'Basic',
    subheader: 'Most popular',
    price: '20',
    description: [
      'Maximum 10 Tests',
      'Maximum 10 Actions Per Test',
      'Maximum 100 Run Per Month',
      'Email support',
    ],
    buttonText: 'Select',
  },
  {
    planType: 'enterprise_plan',
    title: 'Enterprise',
    price: '?',
    description: [
      'Custome Number of Tests',
      'Custome Number of Actions Per Test',
      'Custome Number of Run Per Month',
      'Phone & email support',
    ],
    buttonText: 'Contact us',
  },
];



const PricingContent = (props) => {
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [message, setMessage] = useState("");
  const handleClick = (plan_id) => {
    if(plan_id==='enterprise_plan'){
       window.location.assign("mailto:support@testivr.com");
    }else if (plan_id==='basic_plan'){
      if(props.payment==null){
        setMessage("Please add a credit card first.");
        setShowFailAlert(true);
      }else{
        showFailAlert(false);
        fetch(`${sysApi.host}/subscription/`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Token ${localStorage.getItem('token')}`
              },
              body: JSON.stringify({
                "price_id": sysApi.basic_plan_id
              })
          }).then(function (response) {
              if (response.status == 200) {
                  response.json().then(function (object) {
                    console.log("subscribed: ", object)
                    props.updatePage();
                  })
              }else{
                console.log(response.json())
              }
          })
      }
    }else if (plan_id==='free_plan'){
      fetch(`${sysApi.host}/subscription/${props.subscription_id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}`
            }
        }).then(function (response) {
            if (response.status == 200) {
                response.json().then(function (object) {
                  console.log("unsubscribe success: ", object)
                  props.updatePage();
                })
            }else{
                console.log("failed: ")
                console.log(response.json())
            }
        })
    }
    
  }
  
  return (
    <React.Fragment>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      
      {/* Hero unit */}
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 1, pb: 1 }}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Plans
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" component="p">
          
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === 12}
              md={4}
            >
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    <Typography component="h2" variant="h3" color="text.primary">
                      ${tier.price}
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      /mo
                    </Typography>
                  </Box>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button fullWidth variant={
                  tier.planType!=props.plan_type?'contained':'outlined'
                  }
                  disabled={(tier.planType==props.plan_type) || (tier.planType==='free_plan' && props.cancel_at_period_end)}
                  onClick={() => {
                    handleClick(tier.planType);
                  }}
                  >
                  
                    {tier.planType==props.plan_type?
                      (!props.cancel_at_period_end?
                        'Current Plan'
                        :
                        'Active until:' +new Date(props.current_period_end*1000).toLocaleDateString("en-US", {year: 'numeric', month: 'long', day: 'numeric'})
                      )
                      :
                      ((tier.planType==='free_plan' && props.cancel_at_period_end)?
                      'Current Plan'
                      :
                      tier.buttonText
                      )
                    }
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
        {
            showFailAlert && <div>
              <Alert severity="error">{message}</Alert>
            </div>
          }
      </Container>
      
    </React.Fragment>
  );
}

export default PricingContent;